import {
  ThreekitSource,
  Asset,
  useAsset,
  useConfigurator
} from '@threekit/lignin'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, OrbitControls } from '@react-three/drei'

function App () {
  const ref = useRef()
  const [assetId, setAssetId] = useState(undefined) //4c62d148-2e3c-4fbe-9e65-40daa171008c
  const [token, setToken] = useState(undefined) //34bc373a-3c71-4e64-998a-53e422532dd4
  const [org, setOrg] = useState(undefined) //willwill
  const [lightIntensity, setLightIntensity] = useState(0.3)
  const [loaded, setLoaded] = useState(false)
  const [hdr, setHdr] = useState('brownPhotostudio_1k.hdr')
  const [msg, setMsg] = useState(false)

  const [hdrs, setHdrs] = useState([
    'brownPhotostudio_1k.hdr',
    'columnsStudio_1k.hdr',
    'cyclorama_1k.hdr',
    'photoStudio_1k.hdr',
    'studioSmall_1k.hdr'
  ])

  useEffect(() => {}, [assetId, token, org])
  function MyModel () {
    useAsset({ id: assetId, refkey: 'my-model' })
    return <Asset refkey='my-model' />
  }

  const loadPrevious = () => {
    let localAsset = localStorage.getItem('assetId')
    let localToken = localStorage.getItem('token')
    let localOrg = localStorage.getItem('org')
    let localHdr = localStorage.getItem('hdr')

    if (localAsset && localToken && localOrg && localHdr) {
      setAssetId(localAsset)
      setToken(localToken)
      setOrg(localOrg)
      setHdr(localHdr)
    } else {
      setMsg('No previous values found, pleae enter some details')
    }
  }

  const load = () => {
    localStorage.setItem('assetId', assetId)
    localStorage.setItem('token', token)
    localStorage.setItem('org', org)
    localStorage.setItem('hdr', hdr)
    setMsg(undefined)

    loaded ? setLoaded(false) : setLoaded(true)
    if (loaded) {
      window.location.reload()
    }
  }
  return (
    <div>
      <p>{msg}</p>
      <p>Light Intensity: {lightIntensity}</p>
      <label htmlFor='asset'>Asset ID</label>
      <input
        placeholder='asset id'
        id='asset'
        value={assetId}
        onChange={e => setAssetId(e.target.value)}
      />
      <br />
      <label htmlFor='token'>Token</label>

      <input
        placeholder='token'
        value={token}
        id='token'
        onChange={e => setToken(e.target.value)}
      />
      <br />
      <label htmlFor='org'>Org Slug</label>
      <input
        placeholder='se-archive-air-compressor'
        value={org}
        id='org'
        onChange={e => setOrg(e.target.value)}
      />
      <br />
      <select id='hdr' onChange={e => setHdr(e.target.value)}>
        {hdrs.map(e => {
          return <option value={e}>{e}</option>
        })}
      </select>

      <button onClick={() => load()}>{loaded ? 'Unload' : 'Load'}</button>
      <button onClick={() => loadPrevious()}>Load previous values</button>
      <br />

      {loaded ? (
        <div>
          <label htmlFor='myRange'>Light Intensity</label>
          <input
            type='range'
            min='0'
            max='1'
            value={lightIntensity}
            className='slider'
            onChange={e => setLightIntensity(e.target.value)}
            id='myRange'
            step='0.1'
          ></input>
          <Canvas style={{ height: '50vh' }}>
            <Suspense fallback={null}>
              <Environment background={false} files={hdr} path='./resources/' />
              <ThreekitSource env='preview' token={token} org={org}>
                <MyModel />
                {/* <Asset
              id='4c62d148-2e3c-4fbe-9e65-40daa171008c'
              withStage={true}
              onAttributes={() => console.log('oh no, i attributed')}
            /> */}
              </ThreekitSource>
            </Suspense>
            <ambientLight intensity={lightIntensity} />
            <OrbitControls object={ref} makeDefault />
          </Canvas>
        </div>
      ) : null}
    </div>
  )
}

export default App
